import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Chat from './Chat';
import DragAndDrop from './DragAndDrop';
import InputBox from './InputBox';
import { AnimatePresence,motion, } from 'framer-motion';
import { ChevronLeft, ChevronRight, Loader, Paperclip, Send, X } from 'lucide-react';

const MainPage = () => {
  const [chats, setChats] = useState([{ id: 1, title: 'New Chat' }]);
  const [activeChat, setActiveChat] = useState(1);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [user, setUser] = useState(null);
  const [showDragDrop, setShowDragDrop] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);


  const handleSend = async () => {
    if (input.trim() || uploadedFile) {
      const userMessage = { text: input, sender: 'user' };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setShowDragDrop(false);
      setIsLoading(true);

      try {
        const formData = new FormData();
        formData.append('query', input);
        if (uploadedFile) {
          formData.append('file', uploadedFile);
        }

        const response = await axios.post(process.env.REACT_APP_BASE_URL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('API Response:', response.data);
        
        const { question, generation, web_search, documents } = response.data;

        // Process document links
        let links = [];
        if (documents && documents.length > 0) {
          links = documents.flatMap(doc => {
            try {
              const parsedDoc = JSON.parse(doc);
              return parsedDoc.map(item => ({ title: item.title, url: item.link }));
            } catch (e) {
              console.error('Error parsing document:', e);
              return [];
            }
          });
        }

        // Combine links and bot's response
        const botMessage = { 
          text: generation, 
          sender: 'bot',
          links: links
        };
        setMessages(prevMessages => [...prevMessages, botMessage]);

        // Optionally add web search info
        if (web_search) {
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Web search was performed for this query.', sender: 'info' }
          ]);
        }

        setUploadedFile(null);
      } catch (error) {
        console.error('Error sending request:', error);
        const errorMessage = { text: 'Sorry, there was an error processing your request.', sender: 'bot' };
        setMessages(prevMessages => [...prevMessages, errorMessage]);
      } finally {
        setIsLoading(false);
        setInput('');
      }
    }
  };


  const handleFileUpload = (file) => {
    console.log('File uploaded:', file.name);
    setUploadedFile(file);
    setShowDragDrop(false);
  };


  const handleLogin = () => {
    setUser({ name: 'John Doe' });
  };

  const handleLogout = () => {
    setUser(null);
  };
  
  const handleRemoveFile = () => {
    setUploadedFile(null);
  };
  const handleNewChat = () => {
    const newChatId = chats.length + 1;
    setChats([...chats, { id: newChatId, title: `New Chat ${newChatId}` }]);
    setActiveChat(newChatId);
    setMessages([]);
    setShowDragDrop(true);
    setUploadedFile(null);
  };

  const handleDeleteChat = (chatId) => {
    const updatedChats = chats.filter(chat => chat.id !== chatId);
    setChats(updatedChats);
    if (activeChat === chatId) {
      setActiveChat(updatedChats[0]?.id || null);
      setMessages([]);
      setShowDragDrop(true);
      setUploadedFile(null);
    }
  };

  console.log("messages,",messages)

  
  return (
    <div className="flex flex-col h-screen bg-indigo-100 text-gray-800">
      <Navbar user={user} onLogin={handleLogin} onLogout={handleLogout} />
      <div className="flex flex-1 overflow-hidden">
        <AnimatePresence>
          {isSidebarOpen && (
            <motion.div
              initial={{ x: -300 }}
              animate={{ x: 0 }}
              exit={{ x: -300 }}
              transition={{ duration: 0.3 }}
              className="relative"
            >
              <Sidebar
                chats={chats}
                activeChat={activeChat}
                setActiveChat={setActiveChat}
                onNewChat={handleNewChat}
                onDeleteChat={handleDeleteChat}
              />
              <button
                onClick={() => setIsSidebarOpen(false)}
                className="absolute top-1/2 -right-3 bg-white p-1 rounded-full shadow-lg text-indigo-600"
              >
                <ChevronLeft size={20} />
              </button>
            </motion.div>
          )}
        </AnimatePresence>
        
        <div className="flex-1 flex flex-col bg-white rounded-lg shadow-lg m-4">
          {!isSidebarOpen && (
            <button
              onClick={() => setIsSidebarOpen(true)}
              className="absolute top-1/2 left-0 bg-white p-1 rounded-full shadow-lg z-10 text-indigo-600"
            >
              <ChevronRight size={20} />
            </button>
          )}
          <div className="flex-1 overflow-y-auto p-4">
            <Chat messages={messages} />
            <AnimatePresence>
              {showDragDrop && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.3 }}
                  className="w-2/3 max-w-lg mx-auto mt-4"
                >
                  <DragAndDrop onFileUpload={handleFileUpload} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="p-4 bg-indigo-50 rounded-b-lg">
            <AnimatePresence>
              {uploadedFile && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.3 }}
                  className="mb-2 flex items-center"
                >
                  <div className="bg-white rounded p-2 flex items-center mr-2 shadow">
                    <span className="text-sm truncate max-w-xs text-indigo-600">{uploadedFile.name}</span>
                    <button
                      onClick={() => setUploadedFile(null)}
                      className="ml-2 text-indigo-400 hover:text-red-500 transition-colors duration-300"
                    >
                      <X size={16} />
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <div className="flex items-center bg-white rounded-lg shadow">
              <button
                onClick={() => document.getElementById('fileInput').click()}
                className="p-2 text-indigo-600 rounded-l-lg hover:bg-indigo-50 transition-colors duration-300"
              >
                <Paperclip size={20} />
              </button>
              <input
                id="fileInput"
                type="file"
                onChange={(e) => handleFileUpload(e.target.files[0])}
                className="hidden"
                accept=".py,.txt"
              />
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && !isLoading && handleSend()}
                className="flex-1 p-2 bg-transparent text-gray-800 focus:outline-none"
                placeholder="Type your message..."
              />
              <button
                onClick={handleSend}
                disabled={isLoading}
                className={`p-2 ${
                  isLoading ? 'bg-indigo-200' : 'bg-indigo-500 hover:bg-indigo-600'
                } text-white rounded-r-lg transition-colors duration-300 flex items-center justify-center`}
              >
                {isLoading ? <Loader className="animate-spin" size={20} /> : <Send size={20} />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;