import React from 'react';
import { LogOut } from 'lucide-react';
import { motion } from 'framer-motion';

const Navbar = ({ user, onLogin, onLogout }) => (
  <motion.nav
    initial={{ y: -100 }}
    animate={{ y: 0 }}
    transition={{ duration: 0.5 }}
    className="bg-gray-900 p-4 flex justify-between items-center shadow-md"
  >
    <h1 className="text-xl font-bold text-white">Python Playground</h1>
    {user ? (
      <div className="flex items-center">
        <span className="mr-4 text-white">{user.name}</span>
        <button
          onClick={onLogout}
          className="bg-red-500 text-white p-2 rounded-lg flex items-center hover:bg-red-600 transition-colors duration-300"
        >
          <LogOut className="mr-2" /> Logout
        </button>
      </div>
    ) : (
      <button
        onClick={onLogin}
        className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition-colors duration-300"
      >
        Login
      </button>
    )}
  </motion.nav>
);

export default Navbar;