import React from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const Sidebar = ({ chats, activeChat, setActiveChat, onNewChat, onDeleteChat }) => (
  <motion.div
    initial={{ x: -300 }}
    animate={{ x: 0 }}
    transition={{ duration: 0.5 }}
    className="w-64 bg-indigo-700 p-4 h-full rounded-r-lg shadow-lg"
  >
    <button
      onClick={onNewChat}
      className="w-full bg-indigo-500 text-white p-2 rounded-lg mb-4 flex items-center justify-center hover:bg-indigo-600 transition-colors duration-300 shadow"
    >
      <Plus className="mr-2" /> New Chat
    </button>
    <AnimatePresence>
      {chats.map((chat) => (
        <motion.div
          key={chat.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className={`p-2 mb-2 cursor-pointer rounded-lg flex justify-between items-center ${
            activeChat === chat.id ? 'bg-indigo-500' : 'hover:bg-indigo-600'
          } text-white shadow`}
        >
          <span onClick={() => setActiveChat(chat.id)}>{chat.title}</span>
          <button
            onClick={() => onDeleteChat(chat.id)}
            className="text-indigo-200 hover:text-red-300 transition-colors duration-300"
          >
            <Trash2 size={16} />
          </button>
        </motion.div>
      ))}
    </AnimatePresence>
  </motion.div>
);

export default Sidebar;