import logo from './logo.svg';
import './App.css';
import AllRoutes from './route/AllRoutes';

function App() {
  return (
    <div>
     <AllRoutes/>
    </div>
  );
}

export default App;
