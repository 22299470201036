
import {Route,Routes} from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import MainPage from "../components/MainPage";

const AllRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={<MainPage/>}/>
        {/* <Route path="/sidebar" element={<Sidebar/>}/> */}
        <Route path="/nav" element={<Navbar/>}/>
    </Routes>
  )
}

export default AllRoutes;