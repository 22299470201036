import React, { useState } from 'react';
import { Upload } from 'lucide-react';
import { motion } from 'framer-motion';

const DragAndDrop = ({ onFileUpload }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    onFileUpload(file);
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.3 }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={`border-2 border-dashed rounded-lg p-8 text-center transition-all duration-300 ${
        isDragging ? 'border-blue-500 bg-blue-100 scale-105' : 'border-gray-400 hover:border-blue-300'
      }`}
    >
      <Upload size={48} className={`mx-auto mb-4 transition-colors duration-300 ${isDragging ? 'text-blue-500' : 'text-gray-400'}`} />
      <p className="text-lg mb-2 font-semibold">Drag and drop your Python file here</p>
      <p className="text-sm text-gray-500">
        Supported file type: .py
      </p>
    </motion.div>
  );
};

export default DragAndDrop;