import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ExternalLink, User, Bot } from 'lucide-react';

const getFaviconUrl = (url) => {
  try {
    const domain = new URL(url).hostname;
    return `https://www.google.com/s2/favicons?domain=${domain}&size=32`;
  } catch (error) {
    console.error('Invalid URL:', url);
    return ''; // Return a default favicon or empty string
  }
};

const LinkTab = ({ link }) => (
  <a
    href={link.url}
    target="_blank"
    rel="noopener noreferrer"
    className="inline-flex items-center px-3 py-1 bg-indigo-100 text-indigo-700 rounded-full text-xs font-medium hover:bg-indigo-200 transition-colors duration-300 mr-2 mb-2"
  >
    <img src={getFaviconUrl(link.url)} alt="" className="w-4 h-4 mr-1" />
    <span className="truncate max-w-[100px]">{link.title}</span>
    <ExternalLink size={12} className="ml-1 text-indigo-400" />
  </a>
);

const Chat = ({ messages }) => {
  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-4">
      <AnimatePresence>
        {messages.map((msg, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {msg.sender === 'user' ? (
              <div className="flex justify-end mb-4">
                <div className="bg-indigo-500 text-white p-3 rounded-lg max-w-[80%]">
                  <div className="font-semibold flex items-center mb-1">
                    <span>You</span>
                    <User size={16} className="ml-2" />
                  </div>
                  <div className="whitespace-pre-wrap">{msg.text}</div>
                </div>
              </div>
            ) : (
              <div className="flex justify-start mb-4">
                <div className="bg-white border border-gray-200 p-3 rounded-lg shadow-sm max-w-[80%]">
                  <div className="font-semibold flex items-center mb-2 text-indigo-600">
                    <Bot size={16} className="mr-2" />
                    <span>Assistant</span>
                  </div>
                  {msg.links && msg.links.length > 0 && (
                    <div className="flex flex-wrap mb-2">
                      {msg.links.map((link, linkIndex) => (
                        <LinkTab key={linkIndex} link={link} />
                      ))}
                    </div>
                  )}
                  <div className="whitespace-pre-wrap text-gray-800">{msg.text}</div>
                </div>
              </div>
            )}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default Chat;



